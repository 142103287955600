@import 'styles/common.scss';

.container {
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: $zindex-toaster;
}

.custom {
  position: fixed;
  background-color: var(--bg-primary-light);
  box-shadow: 0px 1px 20px rgba(20, 30, 46, 0.12);
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  padding: 12px 16px;
  z-index: $zindex-toaster;

  &.top {
    top: 24px;
  }

  &.bottom {
    bottom: 24px;
  }

  &.left {
    left: 24px;
  }

  &.center {
    transform: translateX(-50%);
    left: 50%;
    @include sm-desktop-down {
      width: calc(100% - 20px);
    }
  }

  &.right {
    right: 24px;
  }
}
.pendingCard {
  position: fixed;
  z-index: $zindex-toaster;
  top: 24px;
  right: 24px;
}
