@import 'styles/common.scss';

.button {
  @include flex-center;
  border: none;
  width: auto;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 12px;
  background: var(--primary-color);
  color: var(--accent-text);
  position: relative;
  box-shadow: 0px 1px 2px rgba(1, 67, 66, 0.06);
  &:hover {
    background: var(--primary-color-dark);
  }
  &:disabled {
    cursor: default;
    background: var(--bg-secondary);
    color: var(--text-tertiary);
  }
  &.loading {
    cursor: default;
    pointer-events: none;
  }
  &.medium {
    @include f-inter-bold(1.6rem, 2.8rem);
    padding: 7px;
  }
  &.large {
    @include f-inter-bold(1.8rem, 2.8rem);
    padding: 12px;
  }
  &.fullWidth {
    width: 100%;
  }
  &.outlined {
    background: var(--bg-primary);
    color: var(--primary-color);
    @include medium {
      border: 2px solid var(--primary-color);
    }
    @include small {
      border: 1px solid var(--primary-color);
    }
    @include large {
      border: 2px solid var(--primary-color);
    }
    border-radius: 14px;
    @include f-inter-semi-bold(1.7rem, 2.4rem);
    &:hover {
      color: var(--primary-color-dark);
      border-color: var(--primary-color-dark);
    }
  }
  .spinner {
    margin-left: 8px;
  }
}
