@import 'styles/common.scss';

.wrapper {
  @include flex-center;
  margin: auto;
  @include medium {
    height: 300px;
  }
  @include large {
    height: 500px;
  }
  @include x-large {
    height: 600px;
  }
  @include xx-large {
    height: 700px;
  }
}

.container {
  position: relative;
}
.spinner {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    circle {
      fill: transparent;
      stroke-width: 15;
      stroke-linecap: round;
      transform-origin: 50% 50%;
      stroke-dasharray: 390;
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-name: rotateClockwise;
    }
  }
}
.xxsmall {
  height: 18px;
  width: 18px;
}
.xsmall {
  height: 24px;
  width: 24px;
}
.small {
  height: 32px;
  width: 32px;
}
.medium {
  height: 48px;
  width: 48px;
}
.large {
  height: 100px;
  width: 100px;
}
.inherit {
  height: 100%;
  width: 100%;
}

.primary {
  circle {
    stroke: var(--primary-color);
  }
}
.secondary {
  circle {
    stroke: var(--bg-primary);
  }
}
.complementary-primary {
  circle {
    stroke: var(--complementary-primary);
  }
}
.black {
  circle {
    stroke: #080c0b;
  }
}

.dark {
  circle {
    stroke: var(--text-primary);
  }
}

.spinnerBackdrop {
  width: 100vw;
  height: 100vh;
  z-index: 151;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--shadow-color);
  @include flex-center;
}

@keyframes rotateClockwise {
  0% {
    stroke-dashoffset: 0;
    transform: rotate(-90deg);
  }
  50% {
    stroke-dashoffset: 390;
    transform: rotate(270deg);
  }
  100% {
    stroke-dashoffset: 0;
    transform: rotate(270deg);
  }
}
