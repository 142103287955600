@import 'styles/common.scss';

.Button {
  @include flex-center;
  background: none;
  border: none;
  width: auto;
  cursor: pointer;
  white-space: nowrap;
  &ColorPrimary {
    &.ButtonVariantContained {
      background: var(--primary-color);
      color: var(--accent-text);
      &:hover {
        background: var(--primary-color-dark);
      }
    }
    &.ButtonVariantOutlined {
      background: var(--bg-primary);
      color: var(--primary-color);
      border: 2px solid var(--primary-color);
      &.ButtonBorderless {
        border: none;
      }
      &:hover {
        color: var(--primary-color-dark);
        border-color: var(--primary-color-dark);
      }
    }
    &.ButtonVariantText {
      color: var(--primary-color);
    }
  }
  &ColorPrimaryLight {
    &.ButtonVariantContained {
      background: var(--primary-color-light);
      color: var(--accent-text);
      &:hover {
        background: var(--primary-color);
      }
    }
    &.ButtonVariantOutlined {
      background: var(--bg-primary);
      color: var(--primary-color-light);
      border: 2px solid var(--primary-color-light);
      &.ButtonBorderless {
        border: none;
      }
      &:hover {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
    &.ButtonVariantText {
      color: var(--primary-color-light);
    }
    &[disabled] {
      opacity: 0.6;
    }
  }
  &ColorPrimaryFaint {
    font-weight: 400;
    &.ButtonVariantContained {
      background: var(--primary-color-faint);
      color: var(--text-primary);
    }
    &.ButtonVariantOutlined {
      background: var(--bg-primary);
      color: var(--primary-color-faint);
      border: 2px solid var(--primary-color-faint);
      &.ButtonBorderless {
        border: none;
      }
    }
    &.ButtonVariantText {
      color: var(--primary-color-faint);
    }
  }
  &ColorSecondary {
    background: var(--bg-secondary);
    color: var(--text-secondary);
    border: 1px solid var(--border-color);
    &.ButtonBorderless {
      border: none;
    }
  }
  &ColorSecondaryFaint {
    font-weight: 400;
    &.ButtonVariantContained {
      background: var(--primary-color-faint);
      color: var(--text-secondary);
    }
    &.ButtonVariantOutlined {
      background: var(--bg-primary);
      color: var(--secondary-color-faint);
      border: 2px solid var(--primary-color-faint);
      &.ButtonBorderless {
        border: none;
      }
    }
    &.ButtonVariantText {
      color: var(--primary-color-faint);
    }
  }
  &ColorDarkGrey {
    color: var(--dark-grey);
  }
  &ColorBlue {
    color: var(--link-color);
  }
  &ColorDanger {
    &.ButtonVariantContained {
      background: var(--complementary-secondary);
      color: var(--accent-text);
    }
    &.ButtonVariantOutlined {
      background: var(--bg-primary);
      color: var(--complementary-secondary);
      border: 2px solid var(--complementary-secondary);
      &.ButtonBorderless {
        border: none;
      }
    }
    &.ButtonVariantText {
      color: var(--complementary-secondary);
    }
    &[disabled] {
      opacity: 0.6;
    }
  }
  &ColorWhite {
    background: $white;
    color: var(--text-primary-v2);
    &.ButtonVariantText {
      color: $white;
      background: transparent;
    }
    &:hover {
      background: var(--ui_teal_light-surface-primary-hover, #f3f3f3);
      box-shadow: 0px 2px 3px 0px rgba(8, 12, 11, 0.06);
    }
  }
  &ColorBlack {
    background: $black;
    color: $white;
    &.ButtonVariantText {
      color: $black;
      background: transparent;
    }
  }
  &[disabled] {
    pointer-events: none;
    cursor: default;
    background: var(--bg-secondary);
    color: var(--primary-color-disabled);
  }
  &Size {
    &Small {
      @include fns-regular(1.4rem, 2.2rem);
      padding: 4px 12px;
      border-radius: 4px;
      &.ButtonIconLeft {
        padding-left: 10px;
        .ButtonIcon {
          margin-right: 6px;
        }
      }
      &.ButtonIconRight {
        padding-right: 10px;
        .ButtonIcon {
          margin-left: 6px;
        }
      }
    }
    &Medium {
      @include fns-extra-bold(1.4rem, 2.6rem);
      padding: 5px 26px;
      border-radius: 10px;
      &.ButtonIconLeft {
        padding-left: 10px;
        .ButtonIcon {
          margin: 0 10px;
        }
      }
      &.ButtonIconRight {
        padding-right: 10px;
        .ButtonIcon {
          margin: 0 10px;
        }
      }
    }
    &Large {
      @include f-inter-semi-bold(1.8rem, 2.5rem);
      padding: 15px 26px;
      border-radius: 10px;
      &.ButtonIconLeft {
        padding-left: 10px;
        .ButtonIcon {
          margin: 0 10px;
        }
      }
      &.ButtonIconRight {
        padding-right: 10px;
        .ButtonIcon {
          margin: 0 10px;
        }
      }
    }
  }
  &FullWidth {
    width: 100%;
  }
  &Loading {
    cursor: default;
    pointer-events: none;
  }
  &Spinner {
    margin-left: 8px;
    &Only {
      margin: 0;
    }
  }
}
