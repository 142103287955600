@import 'styles/common.scss';

.Deadline {
  @include flex-center;
  column-gap: 4px;
  border-radius: 16px;
  @include f-inter-regular-2(1.3rem, 1.8rem);
  padding: 4px 8px;
}
.silver {
  background: #f9f9f9;
  border: 0.5px solid #ebebf1;
  color: #646568;
}
.yellow {
  background: #fff8df;
  border: 0.5px solid #fde66e;
  color: #c3a815;
}
.orange {
  background: #ffeedf;
  border: 0.5px solid #ffd5ae;
  color: #ec7938;
  font-weight: 600;
}
.red {
  background: #ffe9e4;
  border: 0.5px solid #ffcbbf;
  color: #f46d42;
  font-weight: 600;
}
