@mixin focus-outline($value: 2px solid var(--primary-color-medium)) {
  outline: $value;
}

@mixin multi-line-ellipsis($lines) {
  display: -moz-box; /* stylelint-disable-line */
  display: -webkit-box; /* stylelint-disable-line */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  word-break: break-all;
}

@mixin hide-scrollbar() {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}

@mixin hide-small() {
  @include mobile {
    display: none;
  }
}

@mixin hide-desktop() {
  @include desktop {
    display: none;
  }
}

@mixin max-height-lines($lines) {
  max-height: $lines * 2.5rem;
  overflow: hidden;
}

@mixin hide-media-breakpoint-small() {
  @include media-breakpoint-small() {
    display: none !important;
  }
}

@mixin hide-media-breakpoint-medium-only() {
  @include media-breakpoint-medium-only() {
    display: none !important;
  }
}

@mixin hide-media-breakpoint-large() {
  @include media-breakpoint-large() {
    display: none !important;
  }
}

@mixin scroll-bar-style() {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--surface-overlay-loud-plus);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
