@import 'styles/common.scss';

.Row {
  @include flex;
  &Align {
    &Start {
      align-items: flex-start;
    }
    &Center {
      align-items: center;
    }
    &End {
      align-items: flex-end;
    }
    &Stretch {
      align-items: stretch;
    }
  }
  &Justify {
    &Start {
      justify-content: flex-start;
    }
    &Center {
      justify-content: center;
    }
    &End {
      justify-content: flex-end;
    }
    &SpaceAround {
      justify-content: space-around;
    }
    &SpaceBetween {
      justify-content: space-between;
    }
  }
  &Wrap {
    flex-wrap: wrap;
  }
  &FullWidth {
    width: 100%;
  }
}
