@import 'styles/common.scss';

.Input {
  @include flex-column;
  position: relative;
  &ElementContainer {
    position: relative;
    width: 100%;
  }

  &Element {
    @include fns-semi-bold(1.6rem, 2.4rem);
    width: 100%;
    color: #383838;
    background: var(--bg-primary);
    padding: 9px 23px 7px 14px;
    -webkit-appearance: none;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    &.hideEndIconController + .hideEndIcon {
      display: none;
    }
    &:active + .hideEndIcon {
      display: block;
    }
    &:hover + .hideEndIcon {
      display: block;
    }

    &[disabled],
    &[readonly] {
      color: var(--bg-disabled);
    }
    &:focus {
      outline: none;
      border-color: var(--primary-color-light);
      & + .hideEndIcon {
        display: block;
      }
    }
    &::placeholder {
      @include fns-regular(1.8rem, 2.5rem);
      color: #989898;
    }
    &[type='password'] {
      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &:blank {
      color: var(--text-disabled);
    }
  }
  $p: &;
  &HasIcon {
    &Left {
      input {
        padding-left: 48px;
      }
    }
    &Right {
      input {
        padding-right: 48px;
      }
    }
  }
  &Icon {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    &Left {
      left: 12px;
    }
    &Right {
      right: 24px;
    }
  }
  &Icon {
    color: var(--text-primary);
    transition: color 0.2s ease-in-out;
    &:disabled {
      color: var(--text-disabled);
    }
  }
  &Label {
    @include flex(center, space-between);
    label {
      @include flex(center);
      @include fns-regular(1.4rem, 1.6rem);
      margin-bottom: 8px;
      color: var(--text-primary);
      .optional {
        @include f-inter-semi-bold(1.1rem, 1.3rem);
        color: rgba(8, 12, 11, 0.5);
        padding-left: 4px;
      }
      .infoToolTip {
        padding-left: 4px;
        line-height: 0;
      }
    }
  }
  &IsInvalid {
    input {
      &,
      &:focus {
        border-color: var(--complementary-secondary);
        background-color: rgba(254, 236, 238, 1);
      }
    }
    #{$p}HelperText {
      color: var(--complementary-secondary);
    }
  }
  .hideEndIcon {
    &:hover {
      display: block;
    }
  }
}
