@import 'styles/common.scss';

$space-distance: 4px;

.Tooltip {
  position: relative;
  display: inline-block;
  &Content {
    @include fns-semi-bold(1.3rem, 1.6rem);
    padding: 8px 12px;
    border-radius: 6px;
    &Position {
      position: absolute;
      z-index: $zindex-tooltip;
    }
    &Rounded {
      box-shadow: 0px 1px 20px 0px rgba(20, 30, 46, 0.12);
      backdrop-filter: blur(6px);
      border-radius: 12px;
    }
    &Color {
      &Primary {
        $background-color: var(--primary-color);
        background: $background-color;
        color: var(--accent-text);
        .arrow-container {
          color: $background-color;
        }
      }
      &Dark {
        $background-color: rgba(9, 20, 37, 0.90);
        background: $background-color;
        color: var(--accent-text);
        .arrow-container {
          color: $background-color;
        }
      }
    }
    &Horizontal {
      &Left {
        left: 0;
      }
      &Center {
        left: 50%;
        transform: translateX(-50%);
      }
      &Right {
        right: 0;
      }
    }
    &Vertical {
      &Top {
        top: 0;
      }
      &Middle {
        top: 50%;
        transform: translateY(-50%);
      }
      &Bottom {
        bottom: 0;
      }
    }
    &Position {
      .arrow-container {
        @include flex-center;
        position: absolute;
      }
      &Top {
        flex-direction: column;
        bottom: 100%;
        padding-bottom: $space-distance;
        .arrow-container {
          width: 100%;
          top: calc(100% - #{$space-distance});
          left: 0;
        }
      }
      &Bottom {
        flex-direction: column-reverse;
        top: 100%;
        padding-top: $space-distance;
        .arrow-container {
          width: 100%;
          bottom: calc(100% - #{$space-distance});
          left: 0;
          .arrow {
            transform: rotate(180deg);
          }
        }
      }
      &Left {
        flex-direction: row;
        right: 100%;
        padding-right: $space-distance;
        .arrow-container {
          height: 100%;
          left: calc(100% - #{$space-distance});
          top: 0;
        }
        .arrow {
          transform: rotate(180deg);
        }
      }
      &Right {
        flex-direction: row-reverse;
        left: 100%;
        padding-left: $space-distance;
        .arrow-container {
          height: 100%;
          right: calc(100% - #{$space-distance});
          top: 0;
        }
      }
    }
    &.v2Design {
        width: 320px;
        white-space: break-spaces;
        border-radius: 12px;
        box-shadow: 0px 1px 20px 0px rgba(20, 30, 46, 0.12);
        padding: 8px 12px;
        @include f-inter-regular(1.3rem, 1.8rem);
    }
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }
}
