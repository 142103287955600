html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  display: inline-table;
}

html {
  width: 100%;
  height: 100%;
  display: table;
}

body {
  width: 100%;
  height: 100%;
  display: table-cell;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/** TODO: revert to older base font, currently done to use vaadin component */

:root {
  /** font-size: 62.5%;  = 10px */
  font-size: 100%; /** = 16px */
}

body {
  /** font-size: 1.4rem; = 14px */
  font-size: 0.875rem; /** = 14px */
}

button:focus {
  outline: none;
}

#__next {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  :root {
    font-size: 75%;
  }
} */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 1rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

body.modal-open {
  overflow: hidden;
}
