@import 'styles/common.scss';

.container {
    @include flex-column-center;
    padding: 10px 6px;
    background-color: var(--surface-overlay-minimal);
    border-radius: 10px;
    .value {
        @include ui-text-ui-15-semibold;
        color: var(--text-primary-v2);
        text-align: center;
    }
    .label {
        @include ui-text-ui-11-medium;
        color: var(--text-icon-tertiary);
    }
}
