@use "sass:math";

@import 'styles/common.scss';

@mixin col-span-widths {
  @for $i from 0 through $grid-cols {
    &#{$i} {
      width: math.div($i, $grid-cols) * 100%;
    }
  }
}

@mixin col-order() {
  @for $i from 0 through $grid-cols {
    &#{$i} {
      order: #{$i};
    }
  }
}

@mixin col-offset() {
  @for $i from 0 through $grid-cols {
    &#{$i} {
      margin-left: math.div($i, $grid-cols) * 100%;
    }
  }
}

@mixin col-pull() {
  @for $i from 0 through $grid-cols {
    &#{$i} {
      right: math.div($i, $grid-cols) * 100%;
    }
  }
}

@mixin col-push() {
  @for $i from 0 through $grid-cols {
    &#{$i} {
      left: math.div($i, $grid-cols) * 100%;
    }
  }
}

@mixin col-align {
  &Start {
    align-self: flex-start;
  }
  &Center {
    align-self: center;
  }
  &End {
    align-self: flex-end;
  }
  &Stretch {
    align-self: stretch;
  }
}

.Col {
  display: block;
  position: relative;
  &Span {
    & {
      @include default {
        @include col-span-widths;
      }
    }
    &MD {
      @include medium {
        @include col-span-widths;
      }
    }
    &SM {
      @include small {
        @include col-span-widths;
      }
    }
    &XS {
      @include x-small {
        @include col-span-widths;
      }
    }
    &LG {
      @include large {
        @include col-span-widths;
      }
    }
    &XL {
      @include x-large {
        @include col-span-widths;
      }
    }
    &XXL {
      @include xx-large {
        @include col-span-widths;
      }
    }
  }
  &Order {
    & {
      @include default {
        @include col-order;
      }
    }
    &MD {
      @include medium {
        @include col-order;
      }
    }
    &SM {
      @include small {
        @include col-order;
      }
    }
    &XS {
      @include x-small {
        @include col-order;
      }
    }
    &LG {
      @include large {
        @include col-order;
      }
    }
    &XL {
      @include x-large {
        @include col-order;
      }
    }
    &XXL {
      @include xx-large {
        @include col-order;
      }
    }
  }
  &Offset {
    & {
      @include default {
        @include col-offset;
      }
    }
    &MD {
      @include medium {
        @include col-offset;
      }
    }
    &SM {
      @include small {
        @include col-offset;
      }
    }
    &XS {
      @include x-small {
        @include col-offset;
      }
    }
    &LG {
      @include large {
        @include col-offset;
      }
    }
    &XL {
      @include x-large {
        @include col-offset;
      }
    }
    &XXL {
      @include xx-large {
        @include col-offset;
      }
    }
  }
  &Pull {
    & {
      @include default {
        @include col-pull;
      }
    }
    &MD {
      @include medium {
        @include col-pull;
      }
    }
    &SM {
      @include small {
        @include col-pull;
      }
    }
    &XS {
      @include x-small {
        @include col-pull;
      }
    }
    &LG {
      @include large {
        @include col-pull;
      }
    }
    &XL {
      @include x-large {
        @include col-pull;
      }
    }
    &XXL {
      @include xx-large {
        @include col-pull;
      }
    }
  }
  &Push {
    & {
      @include default {
        @include col-push;
      }
    }
    &MD {
      @include medium {
        @include col-push;
      }
    }
    &SM {
      @include small {
        @include col-push;
      }
    }
    &XS {
      @include x-small {
        @include col-push;
      }
    }
    &LG {
      @include large {
        @include col-push;
      }
    }
    &XL {
      @include x-large {
        @include col-push;
      }
    }
    &XXL {
      @include xx-large {
        @include col-push;
      }
    }
  }
  &Align {
    & {
      @include default {
        @include col-align;
      }
    }
    &MD {
      @include medium {
        @include col-align;
      }
    }
    &SM {
      @include small {
        @include col-align;
      }
    }
    &XS {
      @include x-small {
        @include col-align;
      }
    }
    &LG {
      @include large {
        @include col-align;
      }
    }
    &XL {
      @include x-large {
        @include col-align;
      }
    }
    &XXL {
      @include xx-large {
        @include col-align;
      }
    }
  }
}
