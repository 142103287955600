@import 'styles/common.scss';

.container {
  column-gap: 12px;
  box-shadow: 0px 4px 10px rgba(45, 47, 48, 0.05);
  border-radius: 8px;
  min-height: 80px;
  height: auto;
  padding: 18px;
  max-width: 450px;
  @include small {
    max-width: 320px;
  }
  &.success {
    background: var(--primary-color);
    .title,
    .icon {
      color: var(--bg-primary);
    }
    .message {
      color: var(--bg-primary);
    }
  }
  &.info {
    background: $black;
    .title,
    .icon {
      color: var(--bg-primary);
    }
    .message {
      color: var(--bg-primary);
    }
  }
  &.warning {
    background: var(--complementary-primary-light);
    .title,
    .icon {
      color: $black;
    }
    .message {
      color: var(--text-primary);
    }
  }
  &.error {
    background: var(--complementary-secondary-light);
    .title,
    .icon {
      color: var(--complementary-secondary);
    }
    .message {
      color: $black;
    }
  }

  .title {
    @include fns-bold(1.4rem, 2.4rem);
  }

  .message {
    @include fns-regular(1.4rem, 2.4rem);
    margin-top: 6px;
    & > a {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }

  .cancelButton {
    align-self: center;
  }
}
