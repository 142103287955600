@import 'styles/common.scss';

$border-color-inactive: rgba(9, 20, 37, 0.2);

.SelectionTickMark {
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 10px 16px;
  background-color: white;
  &:hover {
    background-color: #f3f3f3;
  }
  label {
    @include f-inter-regular(1.6rem, 2.2rem);
    order: 1;
    margin-left: 0;
    color: var(--text-primary);
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    appearance: none;
    width: 100%;
    height: 100%;
    order: 2;
    display: inline-block;
    margin: 0;
    outline: none;
    cursor: pointer;
    &:checked {
      border-color: var(--primary-color);
      border-width: 2px;
      & ~ label {
        .tick {
          color: var(--primary-color);
        }
        .tickSquare {
          color: white;
          background-color: var(--primary-color);
          height: 20px;
          border-radius: 5px;
          width: 20px;
          padding: 0 3px;
        }
        .tickCircle {
          color: white;
          background: var(--surface-surface-brand, #009491);
          height: 20px;
          border-radius: 50%;
          width: 20px;
          padding: 6px;
          box-shadow: 0px 1px 1px 0px rgba(1, 67, 66, 0.05);
          & > div {
            position: absolute;
            top: 6px;
            width: 8px;
            height: 8px;
            background: white;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .tick,
  .untick {
    display: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .tickSquare,
  .tickCircle,
  .untickSquare {
    display: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  input:checked ~ label .tick {
    display: inline-block;
  }
  input:checked ~ label .tickSquare {
    display: inline-block;
  }
  input:checked ~ label .tickCircle {
    display: inline-block;
  }
  input:not(:checked) ~ label .untick {
    display: inline-block;
    border-radius: 50%;
    border: 1.6px solid black;
    width: 20px;
    height: 20px;
    border: 1.5px solid rgba(8, 12, 11, 0.3);
  }
  input:not(:checked) ~ label .untickSquare {
    display: inline-block;
    border-radius: 5px;
    border: 1.5px solid rgba(8, 12, 11, 0.3);
    width: 20px;
    height: 20px;
    /* shadow/bottom/bottom-01 */
    box-shadow: 0px 1px 1px 0px rgba(8, 12, 11, 0.04);
  }

  &.disabled {
    background: var(--border-on-lights-03);
    input {
      border: none;
    }
    label {
      color: var(--border-border-30);
    }
  }
}
