@import "styles/common.scss";

.container {
    .content {
        @include f-inter-regular(1.5rem, 2.4rem);
        word-break: break-word;
        white-space: pre-line;
        color: var(--text-primary);
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li,
    span {
        @include f-inter-regular(1.5rem, 2.4rem);
        color: var(--text-primary);
        margin-bottom: 12px;
    }
    ul {
        list-style: disc;
        padding-left: 24px;
        margin-bottom: 12px;
        li {
            margin-bottom: 2px;
        }
    }
    *:last-of-type {
        margin-bottom: 0;
    }
}
