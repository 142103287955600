@import 'styles/common.scss';

$background-color: rgba(100, 101, 104, 0.05);
$border-color: rgba(9, 20, 37, 0.2);

.container {
  @include sm-desktop-down {
   justify-content: space-between;
  }
  @include flex(center);
  width: 100%;
  background-color: $background-color;
  border: none;
  padding: 0;
  margin: 0;
  padding: 2px;
  border-radius: 16px;
  .item {
    @include sm-desktop-down {
      flex: auto;
     }
    flex: 1;
    text-align: center;
    padding: 10px 0;
    position: relative;
    input {
      cursor: pointer;
      border-radius: 14px;
      display: inline-block;
      appearance: none;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      &:checked {
        background: var(--bg-primary);
        transition: background 0.2s ease-in-out;
        box-shadow: 0px 0px 12px rgba(18, 16, 51, 0.1);
        & ~ label {
          color: var(--black-color);
          font-weight: 600;
          transition: color 0.2s ease-in-out;
        }
      }
    }
    label {
      @include f-inter-regular(1.5rem, 1.8rem);
      color: var(--dark-grey);
      pointer-events: none;
      z-index: 1;
      width: 100%;
    }
  }
  &.hasBorder {
    border: 1px solid $border-color;
    border-radius: 8px;
    .item {
      input {
        border-radius: 6px;
      }
    }
  }
}
