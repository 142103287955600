@import "styles/common.scss";

.badge {
    @include flex-center;
    @include f-inter-regular-2(1.1rem, 1.5rem);
    height: 20px;
    min-width: 20px;
    padding: 2.5px 6px 2.5px;
    background-color: var(--surface-critical);
    color: var(--text-icon-text-icon-inverted-primary);
    border-radius: 100px;
}
