@import 'styles/common.scss';

.Chip {
  @include fns-semi-bold(2rem, 2.728rem);
  display: inline-block;
  padding: 6px 12px;
  border-radius: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: normal;
  &Color {
    &Primary {
      background-color: var(--primary-color);
      color: var(--accent-text);
    }
    &Secondary {
      background: var(--bg-secondary);
      color: var(--text-primary);
    }
    &Tertiary {
      background: var(--bg-primary);
      color: var(--text-primary);
    }
  }
}
