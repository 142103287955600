@import 'styles/common.scss';

$placeholder-color: rgba(9, 20, 37, 0.5);

.container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  .header {
    @include flex(center, space-between);
    .label,
    .error {
      @include f-inter-regular(1.4rem, 1.6rem);
    }
    .label {
      color: rgba(9, 20, 37, 0.74);
      & > span {
        @include f-inter-semi-bold(1.1rem, 1.3rem);
        color: rgba(8, 12, 11, 0.5);
      }
    }
    .error {
      color: var(--complementary-secondary);
    }
  }
  .trigger {
    @include f-inter-regular(1.5rem, 2.2rem);
    @include flex(center);
    position: relative;
    width: 100%;
    background: var(--bg-primary);
    border-radius: 8px;
    padding: 10px 40px 10px 16px;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    .selectedValue {
      color: var(--black-color);
    }
    .buttonLabel {
      color: $placeholder-color;
    }
    &[aria-disabled] {
      color: var(--text-tertiary);
    }
  }
  .hasborder {
    border: 1px solid rgba(9, 20, 37, 0.2);
  }
  .header ~ .trigger {
    margin-top: 4px;
  }
  .chevron {
    color: rgba(9, 20, 37, 0.74);
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .dropdown {
    position: absolute;
    background: var(--bg-primary);
    border-radius: 8px;
    box-shadow: 0px 1px 20px rgba(20, 30, 46, 0.12);
    z-index: $zindex-dropdown;
    min-width: 272px;
    max-width: 100%;
    width: 100%;
    top: calc(100% - 40px);
    left: 0;
    @include small {
      max-width: 410px;
    }
    &:focus {
      outline: 0;
    }
    .options {
      height: auto;
      max-height: 288px;
      overflow: auto;
      border: none;
      padding: 0;
      margin: 0;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--scroll-bar-color);
        box-shadow: 0 0 1px rgba($color: $black, $alpha: 0.5);
      }
    }
    .search {
      width: 100%;
      position: relative;
      input {
        @include f-inter-regular(1.5rem, 1.8rem);
        border-radius: 8px 8px 0 0;
        color: var(--text-primary);
        width: 100%;
        padding: 11px 40px 11px 16px;
        &,
        &:focus {
          border: none;
          border-bottom: 1px solid #ced0d4;
          outline: none;
        }
        &::placeholder {
          @include f-inter-regular(1.5rem, 1.8rem);
          color: $placeholder-color;
        }
      }
      .close {
        background: transparent;
        padding: 0;
        border: none;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: default;
      }
    }
    .search ~ .options {
      max-height: 248px;
    }
    .option {
      @include f-inter-regular(1.5rem, 2.2rem);
      position: relative;
      color: var(--black-color);
      padding: 9px 0 9px 16px;
      width: 100%;
      cursor: default;
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        background: rgba(100, 101, 104, 0.05);
      }
      .checkMark {
        display: none;
      }
      &:focus {
        outline: 0;
      }
      &[aria-selected='true'] {
        .checkMark {
          display: block;
          color: var(--primary-color);
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.groupEnd {
        border-bottom: 1px solid #ced0d4;
      }
      .deleteLabel {
        color: var(--complementary-secondary);
      }
    }
  }
}
