@import 'styles/common.scss';

.TouchpointCard {
  background: var(--ui-teal-light-surface-tertiary);
  border-radius: 12px;
  &:hover {
    background: var(--ui-teal-light-surface-tertiary-hover);
  }
  @include mobile {
    padding: 20px;
  }
  @include desktop {
    padding: 28px;
  }
  .type {
    @include f-inter-semi-bold(1.4rem, 2rem, 1.3rem, 1.8rem);
  }
  .job {
    color: var(--primary-color);
  }
  .internship {
    color: #1d7bc3;
  }
  .title {
    @include f-inter-bold(2rem, 2.8rem, 1.6rem, 2.2rem);
  }
  .detail {
    margin-top: 12px;
    column-gap: 6px;
    row-gap: 6px;
    &Row {
      @include f-inter-regular(1.4rem, 2rem, 1.3rem, 1.8rem);
      color: rgba(8, 12, 11, 0.74);
      .dot {
        margin: 0 4px;
      }
    }
  }
  .chip {
    @include f-inter-regular(1.5rem, 1.8rem);
    color: var(--dark-grey);
    border: 0.5px solid rgba(8, 12, 11, 0.15);
    border-radius: 16px;
    background: var(--ui-teal-light-bg-primary);
    padding: 8px 10px;
    text-transform: capitalize;
    &Deadline {
      padding: 8px 10px;
      @include f-inter-regular(1.5rem, 1.8rem);
    }
  }
}
.link {
  flex: 1;
}
