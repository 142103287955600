@import 'styles/common.scss';

.ProfilePics {
  @include flex(center);
  &:first-child {
    margin: 0;
    margin-right: -4px;
  }
  .img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 0.5px solid rgba(8, 12, 11, 0.1);
    object-fit: cover;
  }
  .randomprofile {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 0.5px solid rgba(8, 12, 11, 0.1);
    @include flex(center, center);
    font-size: 14px;
    &Border {
      width: 29px;
      height: 29px;
      padding: 1px;
      background-color: var(--bg-primary);
      border-radius: 50%;
      margin: -5px;
    }
  }
  .safari {
    font-size: 12px;
  }
  .count {
    background: var(--ui-teal-light-surface-tertiary);
  }
}
