@import 'styles/common.scss';

.InputV2 {
  @include flex-column;
  width: 100%;
  position: relative;
  &Label {
    @include flex(center, space-between);
    @include f-inter-regular(1.4rem, 2rem);
    color: #080c0b;
    margin-bottom: 4px;
    & > div {
      @include flex(center, flex-start);
      column-gap: 4px;
    }
    &HelperText {
      color: #e5132e;
    }
  }
  .optional {
    @include f-inter-regular(1.2rem, 1.7rem);
    color: rgba(8, 12, 11, 0.5);
  }
  .infoToolTip {
    line-height: 0;
    display: inline-block;
  }
  &Element {
    @include f-inter-regular(1.6rem, 2.6rem);
    color: #080c0b;
    border: 1.5px solid rgba(8, 12, 11, 0.15);
    box-shadow: 0px 1px 1px rgba(8, 12, 11, 0.04);
    border-radius: 10px;
    padding: 11px 14px;
    width: 100%;
    &[disabled],
    &[readonly] {
      background: rgba(239, 241, 243, 0.97);
      border: none;
    }
    &:focus,
    &:hover {
      outline: none;
      border: 1.5px solid rgba(8, 12, 11, 0.3);
      box-shadow: 0px 1px 3px rgba(8, 12, 11, 0.05);
    }
    &:focus {
      ~ .tabText {
        visibility: visible;
      }
    }
    &::placeholder {
      @include f-inter-regular(1.6rem, 2.6rem);
      color: rgba(8, 12, 11, 0.44);
    }
    &[type='password'] {
      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }
    }
    &TabText:focus {
      padding: 11px 155px 11px 14px;
    }
    &EnterText:focus {
      padding: 11px 100px 11px 14px;
    }
    &RightIcon {
      padding: 11px 40px 11px 14px;
    }
  }
  &Secondary {
    & > input {
      border: none;
    }
  }
  .context {
    @include f-inter(1.2rem, 1.7rem);
    color: rgba(8, 12, 11, 0.62);
    &Container {
      @include flex(center);
      margin-top: 7px;
    }
    &Icon {
      width: 15px;
      height: 15px;
    }
  }

  .tabText {
    position: absolute;
    bottom: 0px;
    right: 16px;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 4px;
    @include f-inter-regular(1rem, 1.4rem);
    color: rgba(8, 12, 11, 0.62);
    visibility: hidden;

    span {
      padding: 3px 5px;
      border-radius: 4px;
      background-color: rgba(8, 12, 11, 0.05);
      @include f-inter-regular(1.2rem, 1.7rem);
      color: rgba(8, 12, 11, 0.74);
    }
  }
  .rightIcon {
    position: absolute;
    bottom: 0px;
    right: 16px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .leftIcon {
    position: absolute;
    left: 12px;
    bottom: 16px;
    & ~ .InputV2Element {
      padding-left: 40px;
    }
  }
}
.InputV2Large {
  & > input {
    padding: 14px 16px;
    @include f-inter-regular(1.8rem, 2.8rem);
    &::placeholder {
      @include f-inter-regular(1.8rem, 2.8rem);
    }
  }
}
.InputV2IsInvalid {
  & > input {
    background: #feecee;
    box-shadow: 0px 1px 1px rgba(8, 12, 11, 0.04);
    &,
    &:focus {
      border: 1.5px solid #e5132e;
    }
  }
  .contextContainer {
    &,
    & > * {
      color: #e5132e;
    }
  }
}
.InputV2IsDisabled {
  & > label,
  .optional,
  .infoToolTip {
    color: rgba(8, 12, 11, 0.3);
  }
  & > input {
    background: rgba(239, 241, 243, 0.97);
    border: none;
    color: rgba(8, 12, 11, 0.3);
    &::placeholder {
      color: rgba(8, 12, 11, 0.3);
    }
    &:focus,
    &:hover {
      border: none;
    }
  }
}
