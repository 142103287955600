@mixin flex($v: null, $h: null) {
  display: flex;
  @if ($v) {
    align-items: $v;
  }
  @if ($h) {
    justify-content: $h;
  }
}

@mixin flex-center {
  @include flex(center, center);
}

@mixin flex-column($v: null, $h: null) {
  @include flex($v, $h);
  flex-direction: column;
}

@mixin flex-column-center {
  @include flex(center, center);
  flex-direction: column;
}

@mixin flex-row($v: null, $h: null) {
  @include flex($v, $h);
  flex-direction: row;
}
@mixin flex-wrap($v: null, $h: null) {
  @include flex($v, $h);
  flex-wrap: wrap;
}

@mixin flex-inline($v: null, $h: null) {
    @include flex($v, $h);
    display: inline-flex;
}
