@import 'styles/common.scss';

.container {
  @include flex(center, space-between);
  column-gap: 6px;
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: $zindex-toaster;
  border-radius: 16px;

  .content {
    @include flex(flex-start);
  }

  &.medium {
    padding: 16px 20px;
    .title {
      @include ui-text-ui-16-semibold;
    }
    .message {
      @include ui-text-ui-14-regular;
    }
    @include media-breakpoint-small {
      width: 350px;
    }
    .content {
      column-gap: 10px;
    }
  }
  &.small {
    padding: 12px 16px;
    .icon-start {
      margin-top: 1px;
    }
    .title {
      @include ui-text-ui-15-semibold;
    }
    .message {
      @include ui-text-ui-13-regular;
    }
    @include media-breakpoint-small {
      width: max-content;
      max-width: 350px;
    }
    .content {
      column-gap: 8px;
      padding: 4px 4px 4px 0;
    }
  }
  &.micro {
    padding: 9px 12px;
    .message {
      @include ui-text-ui-13-semibold;
    }
    .content {
      column-gap: 6px;
    }
  }
  &.borderRounded {
    border-radius: 99px;
  }

  backdrop-filter: blur(50px);
  color: var(--text-icon-text-icon-inverted-primary);
  max-width: 100%;

  //acknowledgement
  border: 0.5px solid var(--border-inverted-20);
  background: var(--surface-overlay-max);
  box-shadow: 0px 1px 2px 0px var(--black-color-04);
  .closeIcon {
    color: var(--ui-teal-light-surface-tertiary-74);
    @include media-breakpoint-small {
      display: none;
    }
  }
  //failure
  &.failure {
    border: 0.5px solid var(--border-border-10);
    background: var(--surface-critical-silent);
    color: var(--surface-critical);
    .closeIcon {
      color: var(--text_icon-secondary);
    }
  }
  .closeIcon {
    color: var(--accent-text);
    margin-left: 2px;
    &.not-micro {
      padding: 5px;
    }
  }
}
