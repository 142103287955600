@import 'styles/common.scss';

.Radio {
  input {
    appearance: none;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--border-color);
    background-color: transparent;
    margin: 0;
    &:checked {
      background-clip: content-box;
      padding: 4px;
      border-color: var(--primary-color);
      background-color: var(--primary-color);
    }
  }
}
