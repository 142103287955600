@import 'styles/common.scss';

.Password {
  position: relative;
  .rightContext {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .inputV2 {
    border: 1.5px solid rgba(8, 12, 11, 0.15);
    box-shadow: 0px 1px 1px rgba(8, 12, 11, 0.04);
    border-radius: 10px;
    padding: 11px 14px;
    &:focus,
    &:hover,
    &:active {
      outline: none;
      border: 1.5px solid rgba(8, 12, 11, 0.3);
      box-shadow: 0px 1px 3px rgba(8, 12, 11, 0.05);
    }
    &.inputInvalid {
      border: 1.5px solid #e5132e;
    }
  }
}
