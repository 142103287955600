@import 'styles/common.scss';

.Selection {
  @include flex;
  label {
    @include fns-regular(1.4rem, 2.4rem);
    color: var(--text-secondary);
    display: inline-block;
    margin: auto 0 auto 8px;
    @include sm-desktop-down {
      margin: auto;
     }
  }
  input {
    flex: none;
    margin: 4px;
    @include fns-regular(1.6rem);
  }

  &--labelFirst {
    flex-direction: row-reverse;

    label {
      margin-left: 0;
    }
  }
}
