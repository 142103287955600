@import 'styles/common.scss';

.plain-text {
  @include f-inter-regular(1.6rem, 2.6rem);
  word-break: break-word;
  white-space: pre-line;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  &.dark-bg {
    a {
      color: var(--accent-text);
    }
  }
  &.link-underlined {
    a {
      text-decoration: underline;
    }
  }
}
.plan-text-sender {
  color: var(--accent-text);
}
