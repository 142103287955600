@import 'styles/common.scss';

$background-color: #faf7f7;
$border-color: rgba(9, 20, 37, 0.2);

.TabRadioGroupV2 {
  .title {
    @include fns-semi-bold(1.4rem, 1.9rem);
    color: #222222;
    padding-bottom: 4px;
  }
  .container {
    @include sm-desktop-down {
      justify-content: space-between;
    }
    @include flex(center);
    width: 100%;
    background-color: $background-color;
    border: none;
    padding: 0;
    margin: 0;
    padding: 2px;
    border-radius: 10px;
    .item {
      @include sm-desktop-down {
        flex: auto;
      }
      flex: 1;
      text-align: center;
      padding: 6px 0;
      position: relative;
      input {
        cursor: pointer;
        border-radius: 14px;
        display: inline-block;
        appearance: none;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        &:checked {
          border: 1px solid var(--border-color);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          background: var(--bg-primary);
          transition: background 0.2s ease-in-out;
          box-shadow: 0px 0px 12px rgba(18, 16, 51, 0.1);
          & ~ label {
            color: #222222;
            font-weight: 700;
            transition: color 0.2s ease-in-out;
          }
        }
      }
      label {
        @include fns-semi-bold(1.6rem, 3rem);
        color: var(--text-secondary);
        pointer-events: none;
        z-index: 1;
        width: 100%;
      }
    }
    &.hasBorder {
      border: 1px solid $border-color;
      border-radius: 8px;
      .item {
        input {
          border-radius: 6px;
        }
      }
    }
  }
}
