@import 'styles/common.scss';

.container {
    border-radius: 9px;
    &:not(.wrap) {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: nowrap;
    }

    &.small {
        @include ui-text-ui-14-medium;
        padding: 4px 8px;
    }
    &.large {
        @include ui-text-ui-15-21-medium;
        padding: 5.5px 10px;
    }
    &.tertiary {
        background-color: var(--surface-overlay-silent);
        color: var(--text-icon-tertiary);
    }
    &.warning {
        background-color: var(--ui_teal_light-surface-warning-silent);
        color: var(--text-icon-on-lights-warning);
    }
    &.primary {
        background-color: var(--surface-brand-silent);
        color: var(--primary-color);
    }
    &.orange {
        background-color: #FFEBDA;
        color: #E97135;
    }
    &.critical {
        background-color: var(--surface-critical-silent);
        color: var(--text_icon-on_lights-critical);
    }
    &.interactive {
        background-color: var(--surface-interactive-silent);
        color: var(--text-icon-interactive);
        cursor: pointer;
    }
}
