@import 'styles/common.scss';

.Salary {
  @include flex-center;
  @include f-inter-regular(1.5rem, 1.8rem);
  color: var(--dark-grey);
  border: 0.5px solid var(--chip-border-color);
  border-radius: 16px;
  background: var(--chip-bg-color);
  padding: 7px 10px;
}
