@import 'styles/common.scss';

$border-color-inactive: rgba(9, 20, 37, 0.2);

.SelectionCheckbox {
  position: relative;
  display: inline-flex;
  padding: 9px 14px;
  background: var(--bg-primary-light);
  border-radius: 14px;
  &.has-horder {
    input {
      border: 1px solid $border-color-inactive;
    }
  }
  label {
    @include f-inter-semi-bold(1.6rem, 2rem);
    order: 1;
    margin-left: 0;
    color: var(--dark-grey);
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    appearance: none;
    width: 100%;
    height: 100%;
    order: 2;
    display: inline-block;
    margin: 0;
    border-radius: 14px;
    outline: none;
    cursor: pointer;
    &:checked {
      border: 2px solid var(--primary-color);
      & ~ label {
        color: var(--primary-color) !important;
      }
    }
  }
  input:checked ~ label .tick {
    display: inline-block;
  }
  input:not(:checked) ~ label .untick {
    display: inline-block;
  }
}
