@use "sass:math";

// Nunito Sans
@mixin fns(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  font-family: 'Nunito Sans';
  @include default {
    @if $font-size-default {
      font-size: math.div($font-size-default, 1.6);
    }
    @if $line-height-default {
      line-height: math.div($line-height-default, 1.6);
    }
  }
  @include small {
    @if $font-size-sm {
      font-size: math.div($font-size-sm, 1.6);
    }
    @if $line-height-sm {
      line-height: math.div($line-height-sm, 1.6);
    }
  }
  @include large {
    @if $font-size-lg {
      font-size: math.div($font-size-lg, 1.6);
    }
    @if $line-height-lg {
      line-height: math.div($line-height-lg, 1.6);
    }
  }
}

// Nunito Sans - light
@mixin fns-light(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include fns(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 300;
}

@mixin fns-regular(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include fns(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 400;
}

// Nunito Sans - semi bold
@mixin fns-semi-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include fns(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}

// Nunito Sans - bold
@mixin fns-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include fns(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

// Nunito Sans - extra bold
@mixin fns-extra-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include fns(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
}

// Nunito Sans - black
@mixin fns-black(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include fns(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
}

// Inter
@mixin f-inter(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  font-family: 'Inter';
  @include default {
    @if $font-size-default {
      font-size: math.div($font-size-default, 1.6);
    }
    @if $line-height-default {
      line-height: math.div($line-height-default, 1.6);
    }
  }
  @include small {
    @if $font-size-sm {
      font-size: math.div($font-size-sm, 1.6);
    }
    @if $line-height-sm {
      line-height: math.div($line-height-sm, 1.6);
    }
  }
  @include large {
    @if $font-size-lg {
      font-size: math.div($font-size-lg, 1.6);
    }
    @if $line-height-lg {
      line-height: math.div($line-height-lg, 1.6);
    }
  }
}

@mixin f-inter-regular(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include f-inter(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@mixin f-inter-regular-2(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include f-inter(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

// Inter - semi bold
@mixin f-inter-semi-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include f-inter(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}

// Inter - bold
@mixin f-inter-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include f-inter(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.27px;
}

// Inter - extra bold
@mixin f-inter-extra-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include f-inter(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Recoleta-Regular';
  src: url('../fonts/Recoleta/Recoleta-Regular.ttf') format(truetype);
}

@font-face {
  font-family: 'Recoleta-light';
  src: url('../fonts/Recoleta/Recoleta-Light.ttf') format(truetype);
}

@font-face {
  font-family: 'Recoleta-SemiBold';
  src: url('../fonts/Recoleta/Recoleta-SemiBold.ttf') format(truetype);
}

@font-face {
  font-family: 'Recoleta-Medium';
  src: url('../fonts/Recoleta/Recoleta-Medium.ttf') format(truetype);
}

@font-face {
  font-family: 'Recoleta-Black';
  src: url('../fonts/Recoleta/Recoleta-Black.ttf') format(truetype);
}
@font-face {
  font-family: 'Recoleta-Bold';
  src: url('../fonts/Recoleta/Recoleta-Bold.ttf') format(truetype);
}

// Recoleta
@mixin frs(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  font-family: 'Recoleta-Regular';
  @include default {
    @if $font-size-default {
      font-size: math.div($font-size-default, 1.6);
    }
    @if $line-height-default {
      line-height: math.div($line-height-default, 1.6);
    }
  }
  @include small {
    @if $font-size-sm {
      font-size: math.div($font-size-sm, 1.6);
    }
    @if $line-height-sm {
      line-height: math.div($line-height-sm, 1.6);
    }
  }
  @include large {
    @if $font-size-lg {
      font-size: math.div($font-size-lg, 1.6);
    }
    @if $line-height-lg {
      line-height: math.div($line-height-lg, 1.6);
    }
  }
}

// Recoleta - light
@mixin frs-light(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include frs(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-family: 'Recoleta-Light';
  font-weight: 300;
}

@mixin frs-regular(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include frs(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-weight: 400;
}

// Recoleta - semi bold
@mixin frs-semi-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include frs(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-family: 'Recoleta-SemiBold';
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}

// Recoleta - bold
@mixin frs-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include frs(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-family: 'Recoleta-Medium';
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

// Recoleta- extra bold
@mixin frs-extra-bold(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include frs(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-family: 'Recoleta-bold';
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
}

@mixin frs-extra-bold-2(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include frs(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-family: 'Recoleta-bold';
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

// Recoleta - black
@mixin frs-black(
  $font-size-default: null,
  $line-height-default: null,
  $font-size-sm: null,
  $line-height-sm: null,
  $font-size-lg: null,
  $line-height-lg: null
) {
  @include frs(
    $font-size-default,
    $line-height-default,
    $font-size-sm,
    $line-height-sm,
    $font-size-lg,
    $line-height-lg
  );
  font-family: 'Recoleta-Black';
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
}

// font-mixins
@mixin ui-text-ui-11-medium() {
  @include f-inter-regular-2(1.1rem, 1.5rem);
}

@mixin ui-text-ui-12-regular() {
  @include f-inter-regular(1.2rem, 1.7rem);
}

@mixin ui-text-ui-12-14-medium() {
  @include f-inter-regular-2(1.2rem, 1.4rem);
}

@mixin ui-text-ui-12-17-medium() {
  @include f-inter-regular-2(1.2rem, 1.7rem);
}

@mixin ui-text-ui-13-regular() {
  @include f-inter-regular(1.3rem, 1.8rem);
}

@mixin ui-text-ui-14-regular() {
  @include f-inter-regular(1.4rem, 2rem);
}

@mixin ui-text-ui-14-22-regular() {
  @include f-inter-regular(1.4rem, 2.2rem);
}

@mixin ui-text-ui-14-22-medium() {
  @include f-inter-regular-2(1.4rem, 2.2rem);
}

@mixin ui-text-ui-14-26-regular() {
  @include f-inter-regular(1.4rem, 2.6rem);
}

@mixin ui-text-ui-16-regular() {
  @include f-inter-regular(1.6rem, 2.6rem);
}

@mixin ui-text-ui-13-medium() {
  @include f-inter-regular-2(1.3rem, 1.8rem);
}

@mixin ui-text-ui-14-medium() {
  @include f-inter-regular-2(1.4rem, 2rem);
}

@mixin ui-text-ui-16-medium() {
  @include f-inter-regular-2(1.6rem, 2.2rem);
}

@mixin ui-text-ui-13-semibold() {
  @include f-inter-semi-bold(1.3rem, 1.8rem);
}

@mixin ui-text-ui-14-semibold() {
  @include f-inter-semi-bold(1.4rem, 2rem);
}

@mixin ui-text-ui-16-20-semibold() {
  @include f-inter-semi-bold(1.6rem, 2.0rem);
}

@mixin ui-text-ui-16-semibold() {
  @include f-inter-semi-bold(1.6rem, 2.2rem);
}

@mixin ui-text-ui-16-22-bold() {
  @include f-inter-bold(1.6rem, 2.2rem);
}

@mixin ui-text-ui-15-semibold() {
  @include f-inter-semi-bold(1.5rem, 2.1rem);
}

@mixin ui-text-ui-15-21-medium() {
  @include f-inter-regular-2(1.5rem, 2.1rem);
}

@mixin heading-h-18-bold() {
  @include f-inter-bold(1.8rem, 2.5rem);
}

@mixin heading-h-18-medium() {
  @include f-inter-regular-2(1.8rem, 2.5rem);
}

@mixin heading-h-24-32-bold() {
  @include f-inter-bold(2.4rem, 3.2rem);
}

@mixin paragraph-p-15-regular() {
  @include f-inter-regular(1.5rem, 2.4rem);
}

// for texts

@mixin ui-heading-h1() {
  @include f-inter-bold(2.8rem, 3.8rem);
}

@mixin ui-heading-h2() {
  @include f-inter-bold(2.0rem, 2.8rem);
}

@mixin ui-paragraph-15-regular() {
  @include f-inter-regular(1.5rem, 2.4rem);
}

@mixin ui-paragraph-14-regular() {
  @include f-inter-regular(1.4rem, 2.0rem);
}

@mixin ui-paragraph-15-semi-bold() {
  @include f-inter-semi-bold(1.5rem, 2.1rem);
}

@mixin ui-paragraph-16-regular() {
  @include f-inter-regular(1.6rem, 2.6rem);
}

