@import 'styles/common.scss';

.container {
    @include flex(center);
    flex-wrap: wrap;
    gap: 8px;
    .images {
      @include flex(center);
      min-height: 32px;
      padding-left: 5px;
      .image-container {
        width: 29px;
        height: 29px;
        padding: 1px;
        background-color: var(--bg-primary);
        margin: -5px;
        .image {
          object-fit: cover;
        }
        .emoji {
          @include flex-center;
          font-size: 14px;
          height: 27px;
          width: 27px;
          &.is-safari {
            font-size: 12px;
          }
        }
        &, .image, .emoji {
          border-radius: 50%;
        }
        .image, .emoji {
          box-shadow: 0px 0px 0px 0.5px #0914251A inset;
        }
      }
      .more-applicants {
        text-align: center;
        background-color: var(--bg-primary);
        padding: 1px;
        .number {
          @include ui-text-ui-13-medium;
          padding: 5px 8px;
          background: var(--ui-teal-light-surface-tertiary);
          box-shadow: 0px 0px 0px 0.5px #0914251A inset;
          color: var(--text_icon-secondary);
        }
        &, .number {
          border-radius: 47px;
        }
      }
    }
    .description {
      @include ui-text-ui-13-medium;
      color: var(--text_icon-secondary);
    }
}
