@import 'styles/common.scss';

.container {
  @include flex-center;
  height: 100%;
  width: 100%;
  @include small {
    padding: 0 24px;
  }
  .content {
    @include flex-column(center, center);
    width: 435px;
    @include small {
      width: auto;
    }
    .title {
      @include fns-bold(3.2rem, 3.6rem, 2.4rem, 3.6rem);
      color: var(--text-primary);
      text-align: center;
      z-index: 1;
    }
    .description {
      @include fns-regular(1.6rem, 2.6rem);
      color: var(--text-tertiary);
      text-align: center;
      padding: 0 8px;
      margin-top: 8px;
      margin-bottom: 24px;
    }
    .refreshButton {
      width: 180px;
    }
  }
}
