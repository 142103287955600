@import 'styles/common.scss';

$placeholder-color: rgba(9, 20, 37, 0.5);

.DropdownV3Container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  .label,
  .error {
    @include f-inter-regular(1.4rem, 1.6rem);
  }
  .label {
    @include flex(center, space-between);
    color: var(--text-primary-light-v2);
    margin-bottom: 4px;
    .optional {
      @include f-inter-semi-bold(1.1rem, 1.3rem);
      color: rgba(8, 12, 11, 0.5);
      padding-left: 4px;
    }
    & > span {
      @include f-inter-regular(1.2rem, 1.7rem);
      color: rgba(8, 12, 11, 0.62);
      padding-left: 4px;
    }
    .infoToolTip {
      line-height: 1;
    }
  }
  .helpertext {
    color: #e5132e;
  }
  .trigger {
    @include flex(center);
    position: relative;
    width: 100%;
    background: var(--bg-primary);
    border-radius: 10px;
    overflow: hidden;
    white-space: nowrap;
    box-shadow: 0px 1px 1px rgba(8, 12, 11, 0.04);
    cursor: pointer;

    &.small {
      padding: 11px 40px 11px 14px;
      @include f-inter-regular(1.6rem, 2.6rem);
    }
    &.large {
      padding: 14px 40px 14px 16px;
      @include f-inter-regular(1.8rem, 2.8rem);
    }

    &.primary {
      border: 1.5px solid rgba(8, 12, 11, 0.15);
    }
    &.secondary {
      border: none;
    }
    &:hover {
      border: 1.5px solid rgba(8, 12, 11, 0.3);
    }
    &:focus {
      outline: none;
    }
    &:active {
      border: 1.5px solid var(--border-on-lights-3);
      outline: 0.5px solid var(--border-on-lights-3);
    }
    &.tertiary {
      border: none;
      background: transparent;
      padding: 0;
      box-shadow: none;
      &:hover {
        border: none;
      }
    }
    .selectedValue {
      color: var(--text-primary-v2);
      @include f-inter-regular(1.6rem,2.6rem);

    }
    .buttonLabel {
      color: #222222;
    }
  }
  .context {
    @include f-inter(1.2rem, 1.7rem);
    color: rgba(8, 12, 11, 0.62);
    &Container {
      @include flex(center);
      margin-top: 7px;
    }
    &Icon {
      width: 15px;
      height: 15px;
    }
  }
  .header ~ .trigger {
    margin-top: 4px;
  }
  .chevron {
    color: rgba(9, 20, 37, 0.74);
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .dropdown {
    position: absolute;
    background: var(--bg-primary);
    border-radius: 8px;
    box-shadow: 0px 1px 20px rgba(20, 30, 46, 0.12);
    z-index: $zindex-dropdown;
    min-width: 272px;
    max-width: 100%;
    width: 100%;
    top: 25px;
    left: 0;
    @include small {
      max-width: 410px;
    }
    &:focus {
      outline: 0;
    }
    &.from-bottom {
      top: calc(100% + 2px);
    }
    .options {
      height: auto;
      max-height: 288px;
      overflow: auto;
      border: none;
      padding: 0;
      margin: 0;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--scroll-bar-color);
        box-shadow: 0 0 1px rgba($color: $black, $alpha: 0.5);
      }
    }
    .search {
      width: 100%;
      position: relative;
      input {
        @include f-inter-regular(1.5rem, 1.8rem);
        border-radius: 8px 8px 0 0;
        color: var(--text-primary);
        width: 100%;
        padding: 11px 40px 11px 16px;
        &,
        &:focus {
          border: none;
          border-bottom: 1px solid #ced0d4;
          outline: none;
        }
        &::placeholder {
          @include f-inter-regular(1.5rem, 1.8rem);
          color: $placeholder-color;
        }
      }
      .close {
        background: transparent;
        padding: 0;
        border: none;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: default;
      }
    }
    .search ~ .options {
      max-height: 248px;
      .option:first-of-type {
        border-radius: unset;
      }
    }
    .showAllBtn {
      @include f-inter-regular-2(1.4rem, 2.2rem);
      width: 100%;
      color: var(--text-icon-interactive);
      border-top: 1px solid rgba(8, 12, 11, 0.10);
      justify-content: flex-start;
      padding: 9px 0 9px 5px;
      border-radius: 0px;
      &:hover {
        background: rgba(100, 101, 104, 0.05);
      }
    }
    .option {
      @include f-inter-regular(1.5rem, 2.2rem);
      position: relative;
      color: var(--black-color);
      padding: 9px 0 9px 16px;
      width: 100%;
      cursor: default;
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        background: rgba(100, 101, 104, 0.05);
      }
      &:first-of-type {
        border-radius: 8px 8px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }
      .selection {
        &Tickmark {
          padding: 0px;
          background: transparent;
          cursor: pointer;
          & > label {
            & > div:first-of-type {
              background: linear-gradient(0deg, rgba(8, 12, 11, 0.3));
              border: 1.5px solid #080C0B4D;
              box-shadow: 0px 1px 3px 0px #080C0B0D;
            }
          }
          &Separator {
            border: none;
            border-bottom: 1px dashed #ced0d4;
          }
          div:last-of-type {
            hr {
              display: none;
            }
          }
        }
      }
      &Icon {
        display: flex;
        align-items: center;
      }
      .listIcon {
        margin-right: 8px;
      }
      .checkMark {
        display: none;
      }
      &:focus {
        outline: 0;
      }
      &[aria-selected='true'] {
        .checkMark {
          display: block;
          color: var(--primary-color);
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.groupEnd {
        border-bottom: 1px solid #ced0d4;
      }
      .deleteLabel {
        color: var(--complementary-secondary);
      }
    }
  }

  &.disabled {
    .header {
      .label {
        color: rgba(8, 12, 11, 0.3);
        & > span {
          color: rgba(8, 12, 11, 0.44);
        }
      }
    }
    .trigger {
      border: none;
      cursor: not-allowed;
      background-color: rgba(239, 241, 243, 0.97);
      .selectedValue,
      .buttonLabel {
        color: rgba(8, 12, 11, 0.3);
      }
    }
  }
  &.isInValid {
    .trigger {
      border: 1.5px solid #e5132e;
      background-color: #feecee;
    }
    .contextContainer {
      &,
      & > * {
        color: #e5132e;
      }
    }
  }
}
