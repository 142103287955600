@import 'styles/common.scss';

@mixin tick-icon($color) {
  background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.9605 1.36115C11.2874 1.61545 11.3463 2.08665 11.092 2.41361L4.79201 10.5136C4.65994 10.6834 4.46131 10.7883 4.2466 10.8017C4.0319 10.8151 3.82178 10.7356 3.66967 10.5835L0.96967 7.88349C0.676777 7.5906 0.676777 7.11572 0.96967 6.82283C1.26256 6.52994 1.73744 6.52994 2.03033 6.82283L4.12958 8.92208L9.90799 1.4927C10.1623 1.16574 10.6335 1.10684 10.9605 1.36115Z' fill='#{$color}'/%3E%3C/svg%3E%0A")
    no-repeat;
}

.CheckboxButton {
  @include flex(center);
  @include ui-text-ui-14-semibold;
  padding: 9px 16px;
  border-radius: 10px;
  background: var(--surface-overlay-silent);
  column-gap: 8px;
  white-space: nowrap;
  text-wrap: nowrap;
  cursor: pointer;
  border: 1px solid transparent;

  input {
    margin: 0;
  }

  &:has(> input:not(:checked)) {
    color: var(--text-primary-v2);
    input {
      border-width: 1.5px;
    }
  }
  &:has(> input:checked) {
    background-color: var(--primary-color);
    color: var(--text-icon-text-icon-inverted-primary);
    input {
      @include tick-icon('%23ffffff');
      border: 1.5px solid #FFFFFFE0;
      background-position: left 2.5px top 2.5px;
    }
  }
}
